<template>
  <div class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between">
        <div class="N-Page T-size-36">Realtime Monitor</div>
      </div>
    </div>

    <!-- Add -->

    <div id="realtimeMap" class="wrap-Main">
      <div class="box-S4">
        <l-map :zoom="zoom" :center="center" style="z-index: 0; height: 65vh">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker
          ref="marker"
            v-for="(item, index) in markers"
            :key="index"
            :lat-lng="item.location"
          >
            <l-icon
              :icon-url="item.iconcheckpoint"
              :icon-size="[32, 32]"
              :icon-anchor="[16, 16]"
            ></l-icon>
            <l-popup ref="popup">{{ item.name }}</l-popup>
          </l-marker>
          <l-marker
          ref="markeraircaft"
            v-for="(item, indexsnow) in markersnow"
            :key="'A'+indexsnow"
            :lat-lng="item.location"
          >
            <l-icon
              :icon-url="item.iconcheckpoint"
              :icon-size="[60, 60]"
              :icon-anchor="[30, 30]"
            ></l-icon>
            <l-popup ref="popup" :options="{ autoClose: false, closeOnClick: false }">{{ item.name }}</l-popup>
          </l-marker>
          
         
        </l-map>
      </div>
      <div class="box-S4">
        <div class="B-carddetail">
          <div class="panal-title">Event Log</div>
          <span class="center">
            <v-row>
              <v-col md="12">
                <v-list-item class="T-size-16">
                  <v-list-item-content class="center"></v-list-item-content>
                  <v-list-item-content class="center">Aircraft</v-list-item-content>
                  <v-list-item-content class="center">Time</v-list-item-content>
                  <v-list-item-content class="center">Latitude</v-list-item-content>
                  <v-list-item-content class="center">Longitude</v-list-item-content>
                  <v-list-item-content class="center">Altitude</v-list-item-content>
                  <v-list-item-content class="center">Track</v-list-item-content>
                  <!-- <v-list-item-content>Speed</v-list-item-content> -->
                  <v-list-item-content class="center">Event</v-list-item-content>
                  <v-list-item-content class="center">Point</v-list-item-content>
                  <v-list-item-content class="center" >Flight</v-list-item-content>
                   <v-list-item-content class="center">Record</v-list-item-content>
                </v-list-item>

                <v-list
                  class="T-size-14"
                  v-for="item in itemEventLog"
                  :key="item.Id"
                >
                  <div
                    class="T-size-16 SemiBold"
                   
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <span
                          v-if="item.t_posntypedescription == 'TransceiverOn'"
                          class="center T-size-18"
                          ><v-icon class="icon-brown"
                            >mdi mdi-checkbox-blank</v-icon
                          ></span
                        >
                        <span
                          v-if="item.t_posntypedescription == 'TransceiverOff'"
                          class="center T-size-18"
                          ><v-icon class="icon-black"
                            >mdi mdi-checkbox-blank</v-icon
                          ></span
                        >
                        <span
                          v-if="item.t_posntypedescription == 'WheelsOn'"
                          class="center T-size-18"
                          ><v-icon class="icon-brown"
                            >mdi mdi-menu-down</v-icon
                          ></span
                        >
                        <span
                          v-if="item.t_posntypedescription == 'WheelsOff'"
                          class="center T-size-18"
                          ><v-icon class="icon-blue"
                            >mdi mdi-menu-up</v-icon
                          ></span
                        >
                      </v-list-item-content>

                      <v-list-item-content class="T-size-18 center">{{
                        item.t_name
                      }}</v-list-item-content>
                      <v-list-item-content class="T-size-18 center">{{
                        item.t_posntime | MM/DD/YYYY hh:mm
                      }}</v-list-item-content>
                      <v-list-item-content class="T-size-18 center">{{
                        item.t_latitude_string
                      }}</v-list-item-content>
                      <v-list-item-content class="T-size-18 center">{{
                        item.t_longitude_string
                      }}</v-list-item-content>
                     
                      <v-list-item-content class="T-size-18 center"
                        >{{ item.t_altitude }} ft</v-list-item-content
                      >
                      <v-list-item-content class="T-size-18 center"
                        >{{ item.t_track }} T</v-list-item-content
                      >
                      <!-- <v-list-item-content class="T-size-18">{{
                        item.t_speed
                      }} kts</v-list-item-content> -->
                      <v-list-item-content class="T-size-18 center">{{
                        item.t_posntypedescription
                      }}</v-list-item-content>
                      <v-list-item-content class="T-size-18 center">{{
                        item.t_georef_ident
                      }}</v-list-item-content>
                      <v-list-item-content class="T-size-18 center">{{
                        item.f_no
                      }}</v-list-item-content>
                      <v-list-item-content class="T-size-18 center"><span
                      v-if="item.f_rec == 1"
                          class="center T-size-18"
                          ><v-icon style="color:red"
                            >mdi-record-rec</v-icon
                          ></span
                        ></v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list>
              </v-col>
            </v-row>
          </span>
        </div>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { latLng, icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LIconDefault,
  LPolyline,
  LIcon,
  LPopup,
} from "vue2-leaflet";
import {
  startOfDay,
  endOfDay,
  format,
  addHours,
  addMinutes,
  differenceInMinutes,
} from "date-fns";
//Add
import { formatDistance } from "date-fns/esm";
import { th } from "date-fns/esm/locale";

import feathersClientUOA from "../plugins/feathers-client-uoa";
import feathersClientSkytrack from "../plugins/feathers-client-skytrack";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import fillerdate from "../utils/filterdate";
import { selectVirtualRow } from "@syncfusion/ej2-vue-grids";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    //LPolyline,
    LIcon,
    LPopup,
    //"v-rotated-marker": Vue2LeafletRotatedMarker,
  },
  data() {
    return {
      //zoom: 8,
      zoom: 8,
      center: L.latLng(7.1874908, 100.6022255),
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      markers: [],
      icon:"https://api.prodigyfly.com/uploads/helicopter_new.png",
      latlngs: [],
      latlngs2: [],
      dataselect: [],
      selectolicenseplate: "นข4557 สงขลา",
      markersnow: [],
      markpoint: "https://api.prodigyfly.com/uploads/helicopter_new.png",
      tempdatalat: 0,
      tempdatalng: 0,
      itemsflight: null,

      //Add
      flightInformation: [],
      itemEventLog: [],

      //Add
      userdata: null,
      menuselectdate: false,
      itemFlightInfo: [],
      actualTimeStart: "",
      actualTimeStop: "",
      ac_name: "-",
      ac_model: "-",
      ac_total_seat: "-",
      ac_limit_weight: "-",
      speed: "-",
      locationLat: "-",
      altitude: "-",
      totalDistance: "-",
    };
  },
  async mounted() {
    
    this.userdata = this.$CurrentUser.GetCurrentUser();
    await this.renderCheckpoint();
    await this.RenderSkyTrac();
     this.$nextTick(() => {
      for(var i =  0; i< this.markersnow.length;i++)
      this.$refs.markeraircaft[i].mapObject.openPopup()
   }) 
  },
  methods: {
    
    Back() {
      this.$router.go(-1);
    },
    async renderCheckpoint() {
      try {
        const q = {};

        var res = await feathersClientSkytrack
          .service("checkpoint")
          .find({ query: q });

        res[0].MapData.forEach((element) => {
          let markcheckpoint = null;
          if (element.type === "Waypoint") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/waypoint.png";
          } else if (element.type === "Fixed Installation") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/oil-rig.png";
          } else if (element.type === "Airport") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/airport.svg";
          } else if (element.type === "Vessel") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/vessel.png";
          } else if (element.type === "Semi-Submersible") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/oil-rig.png";
          } else if (element.type === "Jackup") {
            markcheckpoint = "https://api.prodigyfly.com/uploads/oil-rig.png";
          }

          var c = {};
          c["location"] = eval(
            "L.latLng(" +
              element.position.lat +
              "," +
              element.position.lng +
              ")"
          );
          c["iconcheckpoint"] = markcheckpoint;
          c["name"] = element.name;

          this.markers.push(c);
        });
      } catch (err) {
        this.$toast.error("ไม่สามารถติดต่อ server ได้" + err);
      }
    },
    async RenderSkyTrac() {
      //Step clear data
      //step 1 quary transport by o_id
      this.markersnow = [];
      this.itemEventLog=[]
      var res = await feathersClientSkytrack.service("realtime-tracking").find();
      this.itemEventLog = res.data

      // let lastRecord = this.itemEventLog.length-1;
      // this.center = L.latLng(this.itemEventLog[lastRecord].t_latitude, this.itemEventLog[lastRecord].t_longitude);
      this.itemEventLog.forEach(async (element) => {
        var c = {};
        c["location"] = eval(
          "L.latLng(" + element.t_latitude + "," + element.t_longitude + ")"
        );
        c["name"] = element.t_name;
        
          c["iconcheckpoint"] = "https://api.prodigyfly.com/uploads/helicopter_new.png";

          this.markersnow.push(c);
      });

      try {
        var res = await feathersClientSkytrack.service("realtime-tracking").on("updated", data =>
        {
          var i = this.markersnow.findIndex((x) => x.name == data.t_name);
          var index_log = this.itemEventLog.findIndex((x) => x.t_name == data.t_name);

          this.itemEventLog[index_log] = data
          if (i == -1) 
          {
            var c = {};
            c["location"] = eval(
              "L.latLng(" + data.t_latitude + "," + data.t_longitude + ")"
            );
            c["iconcheckpoint"] = "https://api.prodigyfly.com/uploads/helicopter_new.png";

            c["name"] = data.t_name;
            this.markersnow.push(c);
          } 
          else 
          {
            this.markersnow[i].location= eval(
              "L.latLng(" + data.t_latitude + "," + data.t_longitude + ")"
            );;
          }
          return;
        }
        );
    }
      catch (error) {
        console.log(error)
      }
    },
    
  },
};
</script>
<style>
.item-click {
  margin-top: 5px;
}

.icon-brown.mdi:before,
.mdi-set {
  color: #ba4a00 !important;
}
.icon-black.mdi:before,
.mdi-set {
  color: #1b2631 !important;
}
.icon-blue.mdi:before,
.mdi-set {
  color: blue !important;
}
</style>