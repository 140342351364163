//var formatDate = require("date-fns/format");
import { addHours } from 'date-fns';
import Vue from 'vue'

Vue.filter('yyyyMMdd', function (value) {
    if (value != null) {
        value = value.replace('T', ' ').replace('Z', '')

        // fix safari problem
        var t = value.split(/[- :]/);
        var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);


        var datadate = new Date(d);
        var dd = datadate.getDate();
        var mm = datadate.getMonth() + 1;
        var yyyy = datadate.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        datadate = dd + "/" + mm + "/" + yyyy;
        return datadate;
    }
})

Vue.filter('HHmm', function (value) {
    if (value != null) {
        value = value.replace('T', ' ').replace('Z', '')

        // fix safari problem
        var t = value.split(/[- :]/);
        var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);

        var time = new Date(d);
        var HH = time.getHours();
        var mm = time.getMinutes();
        if (HH < 10) {
            HH = "0" + HH;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        time = HH + ":" + mm;
        return time;
    }
})


Vue.filter('HHmmAdd7Hour', function (value) {
    if (value != null) {
        value = value.replace('T', ' ').replace('Z', '')

        // fix safari problem
        var t = value.split(/[- :]/);
        var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);

        var time = addHours(new Date(d), 7);
        var HH = time.getHours();
        var mm = time.getMinutes();
        if (HH < 10) {
            HH = "0" + HH;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        time = HH + ":" + mm;
        return time;
    }
})


Vue.filter('yyyyMMddHHmm', function (value) {
    if (value != null) {
        value = value.replace('T', ' ').replace('Z', '')

        // fix safari problem
        var t = value.split(/[- :]/);
        var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);

        var datadate = new Date(d);
        var dd = datadate.getDate();
        var MM = datadate.getMonth() + 1;
        var yyyy = datadate.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }
        if (MM < 10) {
            MM = "0" + MM;
        }

        var HH = datadate.getHours();
        var mm = datadate.getMinutes();
        if (HH < 10) {
            HH = "0" + HH;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        datadate = dd + "/" + MM + "/" + yyyy + " " + HH + ":" + mm;
        return datadate;
    }
})

Vue.filter('yyyyMMddHHmmAdd7Hour', function (value) {
    if (value != null) {
        value = value.replace('T', ' ').replace('Z', '')

        // fix safari problem
        var t = value.split(/[- :]/);
        var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);

        var datadate = addHours(new Date(d), 7);
        var dd = datadate.getDate();
        var MM = datadate.getMonth() + 1;
        var yyyy = datadate.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }
        if (MM < 10) {
            MM = "0" + MM;
        }

        var HH = datadate.getHours();
        var mm = datadate.getMinutes();
        if (HH < 10) {
            HH = "0" + HH;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        datadate = dd + "/" + MM + "/" + yyyy + " " + HH + ":" + mm;
        return datadate;
    }
})

Vue.filter('HHmmss', function (value) {
    if (value != null) {
        return new Date(value).getHours() + ":" + new Date(value).getMinutes() + ":" + new Date(value).getSeconds();
    }
})
